<template>
  <v-container class="searchResults fill-height justify-center">
    <v-row>
      <v-col cols="12">
        <h3 style="text-align: center">
          Search Results for <b>{{ searchParams.searchPhrase }} </b>
        </h3>
        <p v-if="!searchResults.hits" style="text-align: center">
          No results found for this search.
        </p>
      </v-col>
    </v-row>
    <template v-if="searchResults.hits">
      <v-row
        v-for="(result, index) in searchResults.hits"
        :key="index"
        ref="results"
        style="max-width: 1200px"
        class="align-center"
      >
        <template>
          <v-card
            min-width="100%"
            max-width="100%"
            style="margin-top: 3%; background: #9989be"
            @click="goToMovieDetails(result)"
          >
            <v-container>
              <v-row align="center" justify="center">
                <v-col xl="2" lg="3" cols="5" class="align-center">
                  <v-img
                    v-if="result.resultPhrases[0]"
                    class="mx-auto"
                    contain
                    max-width="135px"
                    :src="result.resultPhrases[0].poster"
                  ></v-img>
                </v-col>
                <v-col xl="10" lg="9" cols="12">
                  <v-card-title class="justify-center quoteTitle"
                    ><div
                      style="
                        word-break: break-word;
                        line-height: normal;
                        font-size: 150%;
                        text-align: center;
                      "
                    >
                      <i>{{ result.title }}</i> ({{ result.year
                      }}<template v-if="result.episodeTitle">
                        , S{{ String(result.season).padStart(2, "0") }} E{{
                          String(result.episodeNumber).padStart(2, "0")
                        }}</template
                      >)
                    </div>
                  </v-card-title>
                  <br />
                  <v-card-text max-width="300" class="text-center quoteText">
                    <p
                      class="quoteText text-center"
                      v-html="getInnerText(result.resultPhrases[0].text)"
                    />
                    <div
                      class="matchingQuotes"
                      v-if="result.resultPhrases.length > 1"
                    >
                      + {{ result.resultPhrases.length - 1 }} Matching Quotes
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>
        <!--        <template v-if="index === 0">-->
        <!--          <v-card-->
        <!--              min-width="100%"-->
        <!--              max-width="100%"-->
        <!--              style="margin-top: 3%; background: #9989be"-->
        <!--          >-->
        <!--            <v-container>-->
        <!--              <v-row align="center" justify="center" style="padding: 12px">-->
        <!--                <InFeedAdsense-->
        <!--                    data-ad-client="ca-pub-1497018985243834"-->
        <!--                    data-ad-slot="6409307439"-->
        <!--                    data-ad-format="fluid"-->
        <!--                    data-ad-layout="in-article"-->
        <!--                    style="min-width: 300px; min-height: 100%"-->
        <!--                >-->
        <!--                </InFeedAdsense>-->
        <!--              </v-row>-->
        <!--            </v-container>-->
        <!--          </v-card>-->
        <!--        </template>-->
      </v-row>
      <v-row id="tester">
        <!-- <v-pagination v-if="searchResults.hits.length === 11 || length > 1" total-visible="0" :length="length" @input="switchPage" v-model="searchParams.page"> </v-pagination> -->
      </v-row>
      <v-row style="margin-top: 5%" justify="center" align="center">
        <v-progress-circular v-if="bottom" indeterminate />
      </v-row>
    </template>
  </v-container>
</template>

<script>
/* eslint-disable no-useless-escape */
import { mapState } from "vuex";
// import BannerAd from "@/components/BannerAd.vue";
import { AmazonAffiliateAds } from "../assets/affiliateLinks";

export default {
  name: "SearchResults",
  data: () => ({
    bottom: false,
    ads: [],
  }),
  components: {
    // BannerAd,
  },
  methods: {
    goToMovieDetails(movieDetails) {
      this.$store.dispatch("goToMovieDetails", {
        movieDetails: movieDetails,
      });
    },
    getInnerText(text) {
      return `"${text}"`;
    },
    switchPage(e) {
      this.searchParams.page = e;
      this.overlay = true;
      let routerQuery = {
        phrase: this.searchParams.searchPhrase,
      };
      this.searchParams.title
        ? (routerQuery.title = this.searchParams.title)
        : "";
      this.searchParams.keyPerson
        ? (routerQuery.keyPerson = this.searchParams.keyPerson)
        : "";
      this.searchParams.selectedGenres
        ? (routerQuery.genres = this.searchParams.selectedGenres.join())
        : "";
      this.searchParams.startYear
        ? (routerQuery.startYear = this.searchParams.startYear)
        : "";
      this.searchParams.endYear
        ? (routerQuery.endYear = this.searchParams.endYear)
        : "";
      this.searchParams.mediaType
        ? (routerQuery.mediaType = this.searchParams.mediaType)
        : "";
      routerQuery.page = e;
      this.$forceUpdate();
      this.$router.push({
        path: "/searchResults",
        query: routerQuery,
      });

      this.$store.dispatch("performSearch", {
        searchParams: this.searchParams,
      });
    },
    async getNextResults() {
      window.onscroll = async () => {
        let scrolledTo = this.$refs.results[this.searchResults.hits.length - 1];

        if (scrolledTo && this.isScrolledIntoView(scrolledTo) && !this.bottom) {
          this.bottom = true;
          await this.$store.dispatch("performSearch", {
            searchParams: this.searchParams,
          });
          this.bottom = false;
        }
      };
    },
    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible;
    },
    getNextAd() {
      return this.ads.shift();
    },
  },
  computed: {
    length() {
      return this.searchResults.hits.length === 11
        ? this.searchParams.page + 1
        : this.searchParams.page;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    ...mapState({
      searchResults: "searchResults",
      searchParams: "searchParams",
    }),
  },
  created() {
    const arr = [...AmazonAffiliateAds];
    let j, x, i;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = arr[i];
      arr[i] = arr[j];
      arr[j] = x;
    }
    this.ads = arr;
  },
  mounted() {
    this.getNextResults();
  },
};
</script>

<style scoped>
.quoteText {
  font-size: 130%;
  color: white;
}
.quoteTitle {
  margin-bottom: 0;
  padding-top: 0;
  color: white;
}
.matchingQuotes {
  font-size: 100%;
  color: white;
}
body {
}
</style>
